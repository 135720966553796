.container {
  display: flex;
  text-transform: uppercase;
  position: relative;
  flex-direction: column;
  color: #ffffff;
  margin-left: 46px;
}

.h3 {
  font-size: 60px;
  line-height: 89%;
  margin-top: 100px;
  margin-bottom: 35px;
}
.wrapper {
  display: flex;
  justify-content: flex-start;
  margin-top: 26px;
  font-size: 90px;
  line-height: 80px;
}
.wrapper h5 {
  font-style: normal;
  font-weight: normal;
  font-size: 21px;
  line-height: 150%;
}

.wrappHeader {
  margin-right: 147px;
}

.anchor {
  display: block;
  position: relative;
  top: -50px;
  visibility: hidden;
}
@media screen and (max-width: 1200px) {
  .wrapper {
    flex-direction: column;
  }
  .wrappHeader {
    margin-bottom: 50px;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1840px) {
  .wrapper div {
    font-size: 4.2vw;
  }
}

@media screen and (max-width: 900px) {
  .container {
    margin-left: 7vw;
  }
  .h3 {
    font-size: 30px;
  }
  .anchor {
    top: -20px;
  }
  .wrappHeader {
    margin-bottom: 50px;
  }
  .wrapper h5 {
    font-size: 14px;
  }
  .wrapper div {
    font-size: 50px;
  }
}

@media screen and (max-width: 700px) {
  .anchor {
    top: -50px;
  }
}
