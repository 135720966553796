.nlo {
  background-image: url(../../img/nlo.png);
  background-repeat: no-repeat;
  position: absolute;
  width: 340px;
  height: 500px;
  right: 0;
}

@media screen and (max-width: 950px) {
  .parallax {
    display: none;
  }
  .nlo {
    display: none;
  }
}
