.footer {
  display: flex;
  width: 95vw;
  margin: auto;
  padding-top: 50px;
  flex-direction: row-reverse;
  justify-content: space-between;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  padding-bottom: 60px;
  text-align: center;
  /* identical to box height, or 21px */

  text-transform: uppercase;
  color: #ffffff;
}
.nav {
  display: flex;
  justify-content: space-between;
}
.nav > * {
  margin-right: 2vw;
  text-decoration: none;
}

@media screen and (min-width: 2560px) {
  .footer {
    font-size: 24px;
  }
}

@media screen and (max-width: 660px) {
  .footer {
    flex-direction: column-reverse;
    letter-spacing: 0.06em;
  }
  .nav {
    margin-left: 6vw;
    flex-direction: column;

    align-items: flex-start;
  }
  .nav > * {
    margin-bottom: 30px;
  }
  .nav:last-child {
    margin-bottom: 60px;
  }
}
