.background {
  background-image: radial-gradient(circle, #551607 0%, #020024 100%);
  filter: blur(200px);
  -webkit-transform: translateZ(0);
  position: absolute;
  height: 800px;
  width: 800px;
  z-index: 0;
  right: 0;
  transition: opacity 1.5s ease-in-out;
  top: -200px;
}

.crow {
  position: absolute;
  width: 360px;
  height: 360px;
  right: 180px;
  top: -50px;
  transition: transform 1.5s ease-in-out;
  animation: do-levitation 3s infinite ease-in-out;
}

@media screen and (min-width: 2560px) {
  .crow {
    background-size: 100%;
    right: 250px;
    width: 450px;
    height: 450px;
  }
  .background {
    height: 1000px;
    width: 1100px;

    top: -270px;
  }
}

@media screen and (max-width: 1960px) {
  .crow {
    background-size: 300px;
    right: 80px;
  }
  .background {
    filter: blur(100px);
    width: 500px;
  }
}

@media screen and (max-width: 1650px) {
  .crow {
    background-size: 300px;
    bottom: -300px;
    right: 30px;
  }
  .background {
    width: 500px;
  }
}

@media screen and (max-width: 1300px) {
  .crow {
    right: 0px;
    top: -90px;
  }
  .background {
    width: 400px;
    top: -270px;
  }
}

@media screen and (max-width: 1200px) {
  .crow {
    background-size: 300px;
    bottom: -300px;
    top: -80px;
    right: 5px;
  }
  .background {
    width: 450px;
    height: 750px;
  }
}

@keyframes do-levitation {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(0, 5em);
  }
  100% {
    transform: translate(0, 0);
  }
}

@media screen and (max-width: 950px) {
  .crow {
    width: 250px;
    height: 250px;
    top: 0px;
  }
  .background {
    width: 350px;
    height: 650px;
    top: -200px;
  }
}
@media screen and (max-width: 600px) {
  .crow {
    width: 35vw;
    height: 35vw;
    top: -100px;
    transition: transform 1.5s ease-in-out;
    animation: do-levitation 3s infinite ease-in-out;
  }
  .background {
    display: none;
  }
}

@keyframes do-levitation-mobile {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(0, 3em);
  }
  100% {
    transform: translate(0, 0);
  }
}

@media screen and (max-width: 450px) {
  .crow {
    top: 0;
    right: -30px;
    animation: do-levitation-mobile 3s infinite ease-in-out;
  }
  .background {
    display: none;
  }
}
