@font-face {
  font-family: 'Monument Extended';
  src: local('Monument Extended'), url(/static/media/MonumentExtended-Regular.73b9994b.otf);
}
@font-face {
  font-family: 'Skr Games';
  src: local('Skr Games'), url("/static/media/Pink Blue.c2e06616.ttf");
}

* {
  font-family: 'Monument Extended';
}

.AboutContainer_container__1oRMc {
  display: flex;
  text-transform: uppercase;
  position: relative;
  flex-direction: column;
  color: #ffffff;
  margin-left: 46px;
}

.AboutContainer_h3__B1Ss8 {
  font-size: 60px;
  line-height: 89%;
  margin-top: 100px;
  margin-bottom: 35px;
}
.AboutContainer_wrapper__35bJO {
  display: flex;
  justify-content: flex-start;
  margin-top: 26px;
  font-size: 90px;
  line-height: 80px;
}
.AboutContainer_wrapper__35bJO h5 {
  font-style: normal;
  font-weight: normal;
  font-size: 21px;
  line-height: 150%;
}

.AboutContainer_wrappHeader__27eSH {
  margin-right: 147px;
}

.AboutContainer_anchor__1c4mD {
  display: block;
  position: relative;
  top: -50px;
  visibility: hidden;
}
@media screen and (max-width: 1200px) {
  .AboutContainer_wrapper__35bJO {
    flex-direction: column;
  }
  .AboutContainer_wrappHeader__27eSH {
    margin-bottom: 50px;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1840px) {
  .AboutContainer_wrapper__35bJO div {
    font-size: 4.2vw;
  }
}

@media screen and (max-width: 900px) {
  .AboutContainer_container__1oRMc {
    margin-left: 7vw;
  }
  .AboutContainer_h3__B1Ss8 {
    font-size: 30px;
  }
  .AboutContainer_anchor__1c4mD {
    top: -20px;
  }
  .AboutContainer_wrappHeader__27eSH {
    margin-bottom: 50px;
  }
  .AboutContainer_wrapper__35bJO h5 {
    font-size: 14px;
  }
  .AboutContainer_wrapper__35bJO div {
    font-size: 50px;
  }
}

@media screen and (max-width: 700px) {
  .AboutContainer_anchor__1c4mD {
    top: -50px;
  }
}

.MeteorContainer_meteor__1KoFn {
  background-image: url(/static/media/Meteor.5c88b1c5.png);
  background-repeat: no-repeat;
  position: absolute;
  width: 281.2px;
  height: 324.54px;
  bottom: -530px;
  right: 230px;
  -webkit-animation: MeteorContainer_do-levitation__1uDan 1.2s alternate ease-in-out infinite;
          animation: MeteorContainer_do-levitation__1uDan 1.2s alternate ease-in-out infinite;
}
.MeteorContainer_background__3bL33 {
  background: radial-gradient(circle, rgb(99, 0, 180) 0%, rgba(2, 0, 36, 1) 100%);
  -webkit-filter: blur(180px);
          filter: blur(180px);
  -webkit-transform: translateZ(0);
  position: absolute;
  height: 400px;
  width: 800px;
  z-index: 0;
  right: 0;
  top: 700px;
}

@-webkit-keyframes MeteorContainer_do-levitation__1uDan {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  100% {
    -webkit-transform: translate(0, 5vw);
            transform: translate(0, 5vw);
  }
}

@keyframes MeteorContainer_do-levitation__1uDan {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  100% {
    -webkit-transform: translate(0, 5vw);
            transform: translate(0, 5vw);
  }
}

@media screen and (max-width: 1560px) {
  .MeteorContainer_meteor__1KoFn {
    top: 500px;
    height: 300px;
    width: 250px;
    right: 100px;
  }
  .MeteorContainer_background__3bL33 {
    top: 600px;
    height: 300px;
    -webkit-filter: blur(100px);
            filter: blur(100px);
    width: 400px;
  }
}
@media screen and (max-width: 1200px) {
  .MeteorContainer_meteor__1KoFn {
    top: 200px;
    right: 100px;
  }
  .MeteorContainer_background__3bL33 {
    -webkit-filter: blur(90px);
            filter: blur(90px);
    top: 200px;
  }
}

@media screen and (max-width: 700px) {
  .MeteorContainer_meteor__1KoFn {
    top: 200px;
    right: 2vw;
    background-size: 30vw;
    width: 30vw;
    height: 30vw;
  }
  .MeteorContainer_background__3bL33 {
    display: none;
  }
}

@media screen and (max-width: 500px) {
  .MeteorContainer_meteor__1KoFn {
    top: 100px;
    right: 2vw;
  }
}

* {
  color: #ffffff;
  text-transform: uppercase;
}
.ContactContainer_name__1xXzc {
  width: 320px;
  background: none;
  border: none;
  font-size: 21px;
  border-bottom: 2px solid white;
  padding-bottom: 20px;
  margin-top: 70px;
  margin-bottom: 50px;
  outline: none;
  -webkit-user-select: text;
          user-select: text;
}

.ContactContainer_containerBtn__34y4M {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ContactContainer_btn__25bPb {
  background: transparent;
  outline: none;
  border: none;
  border-radius: 50%;
  -webkit-transform: translate(-50%, 0%);
          transform: translate(-50%, 0%);
  box-sizing: border-box;
  font-size: 27px;
  line-height: 150%;
  width: 240px;
  height: 240px;
  letter-spacing: 0.05em;
  margin-left: 200px;
  margin-top: -50px;
}

.ContactContainer_circle__1uljP {
  stroke-dasharray: 1000;
  stroke-dashoffset: 0;
  cursor: pointer;
  transition: all 1.8s ease-in-out;
}
.ContactContainer_circleDec__3effS {
  fill-opacity: 0.4;
  stroke-dashoffset: 1000;
}
.ContactContainer_anchor__Ke6wS {
  display: block;
  position: relative;
  top: -50px;
  visibility: hidden;
}

.ContactContainer_btn__25bPb > svg {
  position: absolute;
  left: 0;
  top: 0;

  stroke: #fff;
  fill: #4f95da;
  stroke-linecap: round;
  stroke-linejoin: round;
  transition: 1s ease-in-out;
}

.ContactContainer_btn__25bPb span {
  color: white;
  font-size: 18px;
  font-weight: 100;
}

h6 {
  font-size: 90px;
  line-height: 80px;
}
.ContactContainer_text__2QS4E {
  width: 92%;
}
textarea {
  width: 100%;
  height: 273px;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.05em;
  padding-right: 1vw;
  background: none;
  outline: none;
  border: none;
  resize: none;
  -webkit-user-select: text;
          user-select: text;
}

::-webkit-scrollbar {
  width: 10px;
  cursor: pointer;
}

::-webkit-scrollbar-track {
  background: #010514;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.8);
  border-radius: 100px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(107, 107, 107);
}

textarea::-webkit-scrollbar-track {
  background: #010514;
  border-radius: 100px;
}

.ContactContainer_container__2EKKX {
  margin-top: 400px;
  margin-left: 46px;
  margin-bottom: 120px;
}
.ContactContainer_wrapper__19Pzk {
  border: 1px solid #ffffff;
  box-sizing: border-box;
  width: 60vw;
  height: 305px;
  padding-left: 15px;
  padding-top: 10px;
}

.ContactContainer_flex__3LRLU {
  display: flex;
  align-items: center;
}
.ContactContainer_subTitle__7FREX {
  font-size: 8px;
  line-height: 10px;
  margin-top: 30px;
  display: flex;
  align-items: flex-end;
  letter-spacing: 0.1em;
}

@media screen and (min-width: 1440px) and (max-width: 2560px) {
  textarea {
    font-size: 23px;
  }
}

@media screen and (min-width: 2560px) {
  .ContactContainer_name__1xXzc {
    font-size: 38px;
    width: 500px;
  }
  button {
    width: 300px;
    height: 300px;
    font-size: 40px;
  }
  .ContactContainer_text__2QS4E {
    width: 95%;
  }
  textarea {
    font-size: 30px;
    width: 100%;
    padding-right: 0.1vw;
  }
  .ContactContainer_wrapper__19Pzk {
    width: 1350px;
  }
  .ContactContainer_subTitle__7FREX {
    font-size: 10px;
  }
}

@media screen and (max-width: 1200px) {
  .ContactContainer_flex__3LRLU {
    flex-direction: column;
  }
  .ContactContainer_wrapp_container__3ONe- {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .ContactContainer_name__1xXzc {
    width: 80vw;
  }
  .ContactContainer_wrapper__19Pzk {
    width: 80vw;
  }
  .ContactContainer_flex__3LRLU {
    width: 80vw;
  }
  textarea {
    width: 75vw;
    font-size: 20px;
  }
  .ContactContainer_btn__25bPb {
    margin-top: 50px;
  }
  .ContactContainer_container__2EKKX {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 900px) {
  h6 {
    margin-left: 9vw;
    font-size: 12vw;
    line-height: 100%;
  }
  .ContactContainer_anchor__Ke6wS {
    top: -18vw;
  }
  .ContactContainer_container__2EKKX {
    margin-top: 150px;
  }
}
@media screen and (max-width: 700px) {
  .ContactContainer_anchor__Ke6wS {
    top: -22vw;
  }
}

@media screen and (max-width: 650px) {
  .ContactContainer_container__2EKKX {
    margin-left: 0;
  }
  h6 {
    font-style: normal;
    font-weight: normal;
  }
}

@media screen and (max-width: 450px) {
  h6 {
    font-size: 30px;
  }
  .ContactContainer_anchor__Ke6wS {
    top: -20px;
  }
  .ContactContainer_containerBtn__34y4M {
    width: 240px;
  }
  .ContactContainer_container__2EKKX {
    margin-top: 50px;
  }
  .ContactContainer_btn__25bPb {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 238px;
  }
}

.FooterContainer_footer__uqvcd {
  display: flex;
  width: 95vw;
  margin: auto;
  padding-top: 50px;
  flex-direction: row-reverse;
  justify-content: space-between;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  padding-bottom: 60px;
  text-align: center;
  /* identical to box height, or 21px */

  text-transform: uppercase;
  color: #ffffff;
}
.FooterContainer_nav__2qsba {
  display: flex;
  justify-content: space-between;
}
.FooterContainer_nav__2qsba > * {
  margin-right: 2vw;
  text-decoration: none;
}

@media screen and (min-width: 2560px) {
  .FooterContainer_footer__uqvcd {
    font-size: 24px;
  }
}

@media screen and (max-width: 660px) {
  .FooterContainer_footer__uqvcd {
    flex-direction: column-reverse;
    letter-spacing: 0.06em;
  }
  .FooterContainer_nav__2qsba {
    margin-left: 6vw;
    flex-direction: column;

    align-items: flex-start;
  }
  .FooterContainer_nav__2qsba > * {
    margin-bottom: 30px;
  }
  .FooterContainer_nav__2qsba:last-child {
    margin-bottom: 60px;
  }
}

h2 {
  font-size: 90px;
  line-height: 80px;
  margin-left: 46px;
  padding-top: 500px;
  margin-bottom: 116px;
  text-transform: uppercase;
  color: #ffffff;
}
.GamesListContainer_container__17hBp {
  position: relative;
}

.GamesListContainer_anchor__10Xeo {
  display: block;
  position: relative;
  top: -150px;
  visibility: hidden;
}

@media screen and (max-width: 774px) {
  .GamesListContainer_anchor__10Xeo {
    top: -230px;
  }
}
@media screen and (max-width: 600px) {
  h2 {
    padding-top: 200px;
  }
  .GamesListContainer_anchor__10Xeo {
    top: -260px;
  }
}
@media screen and (max-width: 550px) {
  h2 {
    padding-top: 100px;
    font-size: 45px;
  }
  .GamesListContainer_anchor__10Xeo {
    top: -160px;
  }
}

@media screen and (max-width: 450px) {
  .GamesListContainer_container__17hBp {
    overflow: hidden;
  }
  .GamesListContainer_anchor__10Xeo {
    top: -130px;
  }
  h2 {
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 89%;
    padding-top: 150px;
    margin-left: 7vw;
  }
}

.ItemList_container__3UyO8 {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  margin-bottom: 100px;
}
h4 {
  font-size: 40px;
  line-height: 89%;
  margin-bottom: 45px;
  text-transform: uppercase;
}
.ItemList_textGooglePlay__3bcJr {
  margin-top: 3px;
  cursor: pointer;
}
.ItemList_arrowGooglePlay__1qs9g {
  height: 35px;
}
.ItemList_wrapper_buttons__1i3ae {
  display: flex;
  align-items: center;
}
.ItemList_wrapper_googleplay__3YyNY {
  display: flex;
  align-items: center;
  margin-left: 60px;
}

.ItemList_wrapper__2Q4-k {
  width: 616px;
  margin-left: 90px;
  z-index: 3;
}
.ItemList_wrapper__2Q4-k p {
  font-size: 15px;
  line-height: 150%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  margin-bottom: 70px;
}
.ItemList_wrapper__2Q4-k img {
  cursor: pointer;
}
.ItemList_wrapRate__HIP2W {
  display: flex;
  align-items: center;
  font-size: 22px;
  height: 30px;
  line-height: 89%;

  margin-left: 30px;
  margin-bottom: 30px;
}

.ItemList_wrapRate__HIP2W p {
  margin-top: 5px;
  margin-left: 10px;
}
.ItemList_background__2ye46 {
  display: flex;
  z-index: 3;
  align-items: flex-end;
  width: 300px;
  height: 300px;
}

@media screen and (max-width: 1280px) {
  .ItemList_container__3UyO8 {
    flex-direction: column;
    margin-bottom: 80px;
  }

  .ItemList_wrapper__2Q4-k {
    margin-top: 40px;
    text-align: center;
    margin-left: 0;
  }
  .ItemList_wrapper__2Q4-k p {
    margin-bottom: 40px;
  }
  .ItemList_wrapper_googleplay__3YyNY {
    margin-left: 0;
    margin-right: 70px;
  }
  .ItemList_wrapper_buttons__1i3ae {
    align-items: center;
    justify-content: space-evenly;
  }
  .ItemList_wrapper_buttons__1i3ae > div {
    margin-right: 60px;
    margin-left: 0;
  }
}

@media screen and (min-width: 1281px) and (max-width: 1440px) {
  .ItemList_container__3UyO8 {
    align-items: flex-start;
  }
  .ItemList_wrapper__2Q4-k {
    width: 450px;
  }
  .ItemList_wrapper__2Q4-k p {
    margin-bottom: 20px;
  }
  .ItemList_wrapper__2Q4-k h4 {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 700px) {
  .ItemList_wrapper__2Q4-k {
    display: flex;
    width: 95vw;
    flex-direction: column;
    margin-left: 0;
    justify-content: center;
    text-align: center;
  }
  .ItemList_wrapper_googleplay__3YyNY {
    margin-left: 0;
    margin-right: 0;
  }
  .ItemList_wrapper__2Q4-k p {
    font-size: 15px;
  }
  .ItemList_wrapper_buttons__1i3ae > div {
    margin-right: 0;
    margin-left: 0;
  }
}

@media screen and (max-width: 500px) {
  h4 {
    font-size: 25px;
    line-height: 100%;
  }
  .ItemList_wrapper_googleplay__3YyNY {
    margin-left: 0;
    margin-right: 0;
  }
}

.NloContainer_nlo__32n7o {
  background-image: url(/static/media/nlo.9dea5550.png);
  background-repeat: no-repeat;
  position: absolute;
  width: 340px;
  height: 500px;
  right: 0;
}

@media screen and (max-width: 950px) {
  .NloContainer_parallax__Pz1kv {
    display: none;
  }
  .NloContainer_nlo__32n7o {
    display: none;
  }
}

.WeaponContainer_weapon__2oM-n {
  background-image: url(/static/media/Weapon.3bb197c8.png);
  background-repeat: no-repeat;
  position: absolute;
  width: 453px;
  height: 742px;
  left: 0;
  bottom: -120px;
}
@media screen and (max-width: 900px) {
  .WeaponContainer_weapon__2oM-n {
    width: 320px;
    left: -100px;
    bottom: -50px;
  }
}
@media screen and (max-width: 670px) {
  .WeaponContainer_weapon__2oM-n {
    bottom: -174px;
  }
}

@media screen and (max-width: 950px) {
  .WeaponContainer_parallax__PoSId {
    display: none;
  }
  .WeaponContainer_weapon__2oM-n {
    display: none;
  }
}

.CrowContainer_background__2EKAr {
  background-image: radial-gradient(circle, #551607 0%, #020024 100%);
  -webkit-filter: blur(200px);
          filter: blur(200px);
  -webkit-transform: translateZ(0);
  position: absolute;
  height: 800px;
  width: 800px;
  z-index: 0;
  right: 0;
  transition: opacity 1.5s ease-in-out;
  top: -200px;
}

.CrowContainer_crow__3UNyK {
  position: absolute;
  width: 360px;
  height: 360px;
  right: 180px;
  top: -50px;
  transition: -webkit-transform 1.5s ease-in-out;
  transition: transform 1.5s ease-in-out;
  transition: transform 1.5s ease-in-out, -webkit-transform 1.5s ease-in-out;
  -webkit-animation: CrowContainer_do-levitation__33gIq 3s infinite ease-in-out;
          animation: CrowContainer_do-levitation__33gIq 3s infinite ease-in-out;
}

@media screen and (min-width: 2560px) {
  .CrowContainer_crow__3UNyK {
    background-size: 100%;
    right: 250px;
    width: 450px;
    height: 450px;
  }
  .CrowContainer_background__2EKAr {
    height: 1000px;
    width: 1100px;

    top: -270px;
  }
}

@media screen and (max-width: 1960px) {
  .CrowContainer_crow__3UNyK {
    background-size: 300px;
    right: 80px;
  }
  .CrowContainer_background__2EKAr {
    -webkit-filter: blur(100px);
            filter: blur(100px);
    width: 500px;
  }
}

@media screen and (max-width: 1650px) {
  .CrowContainer_crow__3UNyK {
    background-size: 300px;
    bottom: -300px;
    right: 30px;
  }
  .CrowContainer_background__2EKAr {
    width: 500px;
  }
}

@media screen and (max-width: 1300px) {
  .CrowContainer_crow__3UNyK {
    right: 0px;
    top: -90px;
  }
  .CrowContainer_background__2EKAr {
    width: 400px;
    top: -270px;
  }
}

@media screen and (max-width: 1200px) {
  .CrowContainer_crow__3UNyK {
    background-size: 300px;
    bottom: -300px;
    top: -80px;
    right: 5px;
  }
  .CrowContainer_background__2EKAr {
    width: 450px;
    height: 750px;
  }
}

@-webkit-keyframes CrowContainer_do-levitation__33gIq {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  50% {
    -webkit-transform: translate(0, 5em);
            transform: translate(0, 5em);
  }
  100% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
}

@keyframes CrowContainer_do-levitation__33gIq {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  50% {
    -webkit-transform: translate(0, 5em);
            transform: translate(0, 5em);
  }
  100% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
}

@media screen and (max-width: 950px) {
  .CrowContainer_crow__3UNyK {
    width: 250px;
    height: 250px;
    top: 0px;
  }
  .CrowContainer_background__2EKAr {
    width: 350px;
    height: 650px;
    top: -200px;
  }
}
@media screen and (max-width: 600px) {
  .CrowContainer_crow__3UNyK {
    width: 35vw;
    height: 35vw;
    top: -100px;
    transition: -webkit-transform 1.5s ease-in-out;
    transition: transform 1.5s ease-in-out;
    transition: transform 1.5s ease-in-out, -webkit-transform 1.5s ease-in-out;
    -webkit-animation: CrowContainer_do-levitation__33gIq 3s infinite ease-in-out;
            animation: CrowContainer_do-levitation__33gIq 3s infinite ease-in-out;
  }
  .CrowContainer_background__2EKAr {
    display: none;
  }
}

@-webkit-keyframes CrowContainer_do-levitation-mobile__3rxfZ {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  50% {
    -webkit-transform: translate(0, 3em);
            transform: translate(0, 3em);
  }
  100% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
}

@keyframes CrowContainer_do-levitation-mobile__3rxfZ {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  50% {
    -webkit-transform: translate(0, 3em);
            transform: translate(0, 3em);
  }
  100% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
}

@media screen and (max-width: 450px) {
  .CrowContainer_crow__3UNyK {
    top: 0;
    right: -30px;
    -webkit-animation: CrowContainer_do-levitation-mobile__3rxfZ 3s infinite ease-in-out;
            animation: CrowContainer_do-levitation-mobile__3rxfZ 3s infinite ease-in-out;
  }
  .CrowContainer_background__2EKAr {
    display: none;
  }
}

.HeaderContainer_header__331Pk {
  display: flex;
  width: 95vw;
  margin: auto;
  padding-top: 30px;
  justify-content: space-between;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;

  line-height: 150%;
  margin-bottom: 130px;
  -webkit-animation: HeaderContainer_menuTranslate__3xtjf 1s ease-in-out;
          animation: HeaderContainer_menuTranslate__3xtjf 1s ease-in-out;

  /* identical to box height, or 21px */

  text-transform: uppercase;
  color: #ffffff;
}
.HeaderContainer_toogle__1mTd1 {
  height: 3px;
  width: 30px;
  position: absolute;
  right: 15px;
  top: 30px;
  background-color: white;
}

.HeaderContainer_toogle__1mTd1::after {
  content: '';
  position: absolute;

  width: 19px;
  height: 2.7px;
  top: 8px;
  right: 0;
  background: white;
}
.HeaderContainer_flexMenu__lS05J {
  display: flex;
  position: fixed;
  width: calc(16.4px + 100vw);
  z-index: 10;
  margin-top: -50px;
  margin-left: -16.4px;
  height: 103vh;
  flex-direction: column;
  background: radial-gradient(ellipse at center, rgba(42, 21, 171, 1) 0%, rgba(2, 0, 36, 1) 72%);
  padding-top: 100px;
}

.HeaderContainer_toogleDecoration__18ePv {
  margin-left: 50px;
  text-decoration: none;
  font-size: 30px;
  margin-bottom: 60px;
  -webkit-transform-origin: top center;
          transform-origin: top center;
  -webkit-animation: HeaderContainer_translate__2lkZK 0.7s alternate ease-in-out;
          animation: HeaderContainer_translate__2lkZK 0.7s alternate ease-in-out;
}
@-webkit-keyframes HeaderContainer_menuTranslate__3xtjf {
  0% {
    -webkit-transform: translatey(-600px);
            transform: translatey(-600px);
  }

  100% {
    -webkit-transform: translatey(0px);
            transform: translatey(0px);
  }
}
@keyframes HeaderContainer_menuTranslate__3xtjf {
  0% {
    -webkit-transform: translatey(-600px);
            transform: translatey(-600px);
  }

  100% {
    -webkit-transform: translatey(0px);
            transform: translatey(0px);
  }
}

@-webkit-keyframes HeaderContainer_translate__2lkZK {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100px);
            transform: translateX(100px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@keyframes HeaderContainer_translate__2lkZK {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100px);
            transform: translateX(100px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}
@-webkit-keyframes HeaderContainer_closeToogleMenu__3o0sW {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-100px);
            transform: translateY(-100px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}
@keyframes HeaderContainer_closeToogleMenu__3o0sW {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-100px);
            transform: translateY(-100px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}
.HeaderContainer_toogle__1mTd1::before {
  content: '';
  position: absolute;
  width: 13px;
  height: 2.7px;
  top: -8px;
  right: 0;
  background: white;
}

.HeaderContainer_close__S0gmx {
  position: absolute;
  right: 4.125vw;
  top: 45px;
  width: 32px;
  height: 32px;
  -webkit-animation: HeaderContainer_closeToogleMenu__3o0sW 0.7s alternate ease-in-out;
          animation: HeaderContainer_closeToogleMenu__3o0sW 0.7s alternate ease-in-out;
}
.HeaderContainer_close__S0gmx:hover {
  opacity: 0.3;
}
.HeaderContainer_close__S0gmx:before,
.HeaderContainer_close__S0gmx:after {
  position: absolute;
  left: 15px;
  content: ' ';
  height: 33px;

  width: 2px;
  background-color: white;
}
.HeaderContainer_close__S0gmx:before {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.HeaderContainer_close__S0gmx:after {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

@media screen and (min-width: 2560px) {
  .HeaderContainer_header__331Pk {
    font-size: 20px;
  }
}

.HeaderContainer_decoration__3fHVL {
  position: relative;
  cursor: pointer;
  line-height: 1;
  text-decoration: none;
}
.HeaderContainer_decorationMobileGame__3lkHz {
  position: relative;
  cursor: pointer;
  line-height: 1;
  text-decoration: none;
}

.HeaderContainer_decoration__3fHVL:after {
  display: block;
  position: absolute;
  left: 0;
  width: 0;
  height: 2px;
  margin-top: 5px;
  background-color: white;
  content: '';
  transition: width 0.3s ease-out;
}

.HeaderContainer_decoration__3fHVL:hover:after,
.HeaderContainer_decoration__3fHVL:focus:after {
  width: 100%;
}

.HeaderContainer_nav__2utg7 {
  display: flex;
  justify-content: space-between;
}

.HeaderContainer_nav__2utg7 > * {
  margin-left: 6vw;
}

.HeaderContainer_load__3G6nW {
  display: none;
}

@media screen and (max-width: 700px) {
  .HeaderContainer_header__331Pk {
    padding-top: 15px;
  }
}

.BombContainer_bomb__RT4Qo {
  background-repeat: no-repeat;
  width: 220px;
  height: 364px;
  -webkit-animation: BombContainer_crown-animation__19M2r 1s ease-in-out;
          animation: BombContainer_crown-animation__19M2r 1s ease-in-out;
}
@media screen and (min-width: 2560px) {
  .BombContainer_bomb__RT4Qo {
    background-size: 100%;
    top: 200px;
    width: 300px;
    height: 500px;
  }
}

@media screen and (max-width: 1600px) {
  .BombContainer_bomb__RT4Qo {
    width: 200px;
    height: 300px;
  }
}

@media screen and (max-width: 1300px) {
  .BombContainer_bomb__RT4Qo {
    width: 150px;
    height: 225px;
  }
}

@media screen and (max-width: 950px) {
  .BombContainer_bomb__RT4Qo {
    top: 50px;
    width: 150px;
    height: 250px;
  }
}

@media screen and (max-width: 680px) {
  .BombContainer_bomb__RT4Qo {
    top: 50px;
    width: 20vw;
    height: 32vw;
    margin-bottom: 50px;
    margin-top: 20px;
  }
}

.BombContainer_load__3XJnA {
  display: none;
}

@-webkit-keyframes BombContainer_crown-animation__19M2r {
  0% {
    -webkit-transform: translatex(-600px);
            transform: translatex(-600px);
  }

  100% {
    -webkit-transform: translatex(0px);
            transform: translatex(0px);
  }
}

@keyframes BombContainer_crown-animation__19M2r {
  0% {
    -webkit-transform: translatex(-600px);
            transform: translatex(-600px);
  }

  100% {
    -webkit-transform: translatex(0px);
            transform: translatex(0px);
  }
}

.BookmarkContainer_bookmark__1dZm9 {
  position: absolute;
  width: 311px;
  -webkit-animation: BookmarkContainer_crown-animation__1S1m6 1s ease-in-out;
          animation: BookmarkContainer_crown-animation__1S1m6 1s ease-in-out;
  right: 0;
}

@media screen and (min-width: 2560px) {
  .BookmarkContainer_bookmark__1dZm9 {
    width: 411px;
  }
}

@media screen and (max-width: 1440px) {
  .BookmarkContainer_bookmark__1dZm9 {
    top: 100px;
    width: 200px;
  }
}
@media screen and (max-width: 900px) {
  .BookmarkContainer_bookmark__1dZm9 {
    width: 200px;
  }
}

@media screen and (max-width: 680px) {
  .BookmarkContainer_bookmark__1dZm9 {
    width: 30vw;
  }
}

@media screen and (max-width: 400px) {
  .BookmarkContainer_bookmark__1dZm9 {
    top: 60px;
  }
}
.BookmarkContainer_load__3R-Xo {
  display: none;
}

@-webkit-keyframes BookmarkContainer_crown-animation__1S1m6 {
  0% {
    -webkit-transform: translatex(600px);
            transform: translatex(600px);
  }

  100% {
    -webkit-transform: translatex(0px);
            transform: translatex(0px);
  }
}

@keyframes BookmarkContainer_crown-animation__1S1m6 {
  0% {
    -webkit-transform: translatex(600px);
            transform: translatex(600px);
  }

  100% {
    -webkit-transform: translatex(0px);
            transform: translatex(0px);
  }
}

.PhoneContainer_phone__2F388 {
  background-image: url(/static/media/longhand.afb197a7.png);
  background-repeat: no-repeat;
  position: relative;
  margin-top: 30px;
  margin-left: -169px;

  background-size: 1298px;
  transition: -webkit-transform 1s ease-in-out;
  transition: transform 1s ease-in-out;
  transition: transform 1s ease-in-out, -webkit-transform 1s ease-in-out;
  height: 900.25px;
}

.PhoneContainer_load__aeRKK {
  -webkit-transform: translateX(-2000px);
          transform: translateX(-2000px);
}

.PhoneContainer_persp__h0zWH {
  width: 300px;
  height: 300px;
  margin-left: 150px;
  background: center center no-repeat;
  background-size: contain;
  -webkit-perspective-origin: 134% 130%;
          perspective-origin: 134% 130%;

  -webkit-perspective: 2000px;

          perspective: 2000px;

  -webkit-transform-style: preserve-3d;

          transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}

.PhoneContainer_video__2MTPg {
  height: 443px;
  width: 187px;
  border-radius: 20px;
  border-top-right-radius: 23.5px;
  margin-left: 796px;
  margin-top: 110px;

  -webkit-transform: skew(-4.25deg, 0deg) rotateZ(-1deg) rotateY(-17deg) rotateX(13.5deg);

          transform: skew(-4.25deg, 0deg) rotateZ(-1deg) rotateY(-17deg) rotateX(13.5deg);
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
  z-index: 3;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  object-fit: fill;
}

@media screen and (min-width: 2000px) {
  .PhoneContainer_phone__2F388 {
    background-size: 1698px;
    height: 100vh;
    margin-left: -20vw;
  }
  .PhoneContainer_persp__h0zWH {
    -webkit-perspective-origin: 1% 47%;
            perspective-origin: 1% 47%;
    -webkit-perspective: 3500px;
            perspective: 3500px;
  }
  .PhoneContainer_video__2MTPg {
    height: 565px;
    width: 234px;
    border-radius: 21px;
    border-top-right-radius: 23.5px;
    margin-left: 1090px;
    margin-top: 144px;
    -webkit-transform: skew(-4.25deg, 0deg) rotateZ(-1deg) rotateY(-17deg) rotateX(13.5deg);
    transform: skew(-4deg, -2.7deg) rotateZ(0deg) rotateY(-20deg) rotateX(15.5deg);
  }
}
@media screen and (min-width: 2200px) {
  .PhoneContainer_phone__2F388 {
    margin-left: -15vw;
  }
}

@media screen and (min-width: 2300px) {
  .PhoneContainer_phone__2F388 {
    margin-left: -10vw;
  }
}
@media screen and (max-width: 1700px) {
  .PhoneContainer_phone__2F388 {
    margin-left: -15vw;
  }
}
@media screen and (max-width: 1440px) {
  .PhoneContainer_phone__2F388 {
    margin-left: -25vw;
    height: 690px;
  }
}
@media screen and (max-width: 1300px) {
  .PhoneContainer_phone__2F388 {
    background-size: 1039px;
    margin-left: -12vw;
  }
  .PhoneContainer_persp__h0zWH {
    -webkit-perspective-origin: 176% 115%;
            perspective-origin: 176% 115%;
    -webkit-perspective: 2000px;
    perspective: 1500px;
  }
  .PhoneContainer_video__2MTPg {
    height: 354px;
    width: 154px;
    border-radius: 16px;
    border-top-right-radius: 16.5px;
    margin-left: 607px;
    margin-top: 88px;
    -webkit-transform: skew(-4.25deg, 0deg) rotateZ(-1deg) rotateY(-17deg) rotateX(13.5deg);
    transform: skew(-4.1deg, 0.5deg) rotateZ(-1deg) rotateY(-11deg) rotateX(11.5deg);
  }
}
@media screen and (max-width: 1170px) {
  .PhoneContainer_phone__2F388 {
    margin-left: -20vw;
  }
}
@media screen and (max-width: 1100px) {
  .PhoneContainer_phone__2F388 {
    margin-left: -25vw;
  }
}
@media screen and (max-width: 1024px) {
  .PhoneContainer_phone__2F388 {
    margin-left: -35vw;
  }
}

@media screen and (max-width: 950px) {
  .PhoneContainer_phone__2F388 {
    background-size: 550px;
    background-image: url(/static/media/Phone.c018a527.png);
    height: 450px;
    margin-top: 50px;
    margin-left: 0;
  }
  .PhoneContainer_persp__h0zWH {
    -webkit-perspective-origin: -6% -2%;
            perspective-origin: -6% -2%;
    -webkit-perspective: 1800px;
            perspective: 1800px;
  }
  .PhoneContainer_video__2MTPg {
    height: 341px;
    width: 151px;
    border-radius: 16px;
    border-top-right-radius: 20.5px;
    margin-left: 185px;
    margin-top: 9px;

    -webkit-transform: skew(-4.5deg, -0.9deg) rotateZ(-1.9deg) rotateY(-15deg) rotateX(15.5deg);

            transform: skew(-4.5deg, -0.9deg) rotateZ(-1.9deg) rotateY(-15deg) rotateX(15.5deg);
  }
}

@media screen and (max-width: 680px) {
  .PhoneContainer_phone__2F388 {
    background-size: 465px;
    height: 450px;
    margin-top: 50px;
  }
  .PhoneContainer_persp__h0zWH {
    -webkit-perspective-origin: -6% -2%;
            perspective-origin: -6% -2%;
    -webkit-perspective: 1500px;
            perspective: 1500px;
  }
  .PhoneContainer_video__2MTPg {
    height: 286px;
    width: 128px;
    border-radius: 13.5px;
    border-top-right-radius: 13.5px;
    margin-left: 132px;
    margin-top: 9px;
    -webkit-transform: skew(-4.5deg, -0.9deg) rotateZ(-1.9deg) rotateY(-15.5deg) rotateX(12.5deg);
            transform: skew(-4.5deg, -0.9deg) rotateZ(-1.9deg) rotateY(-15.5deg) rotateX(12.5deg);
  }
}
@media screen and (max-width: 600px) {
  .PhoneContainer_phone__2F388 {
    margin-top: -100px;
  }
}

@media screen and (max-width: 550px) {
  .PhoneContainer_phone__2F388 {
    margin-left: -80px;
  }
}

@media screen and (max-width: 500px) {
  .PhoneContainer_phone__2F388 {
    margin-left: -80px;
  }
}
@media screen and (max-width: 450px) {
  .PhoneContainer_phone__2F388 {
    margin-left: -133px;
    height: 350px;
  }
}

@media screen and (max-width: 360px) {
  .PhoneContainer_phone__2F388 {
    margin-left: -173px;
  }
}

.TitleContainer_h1__zYHeA {
  font-family: 'Skr Games';
  font-style: normal;
  font-weight: normal;
  font-size: 180px;
  line-height: 39%;
  color: #fff;
  text-align: center;
  opacity: 1;
  transition: opacity 2s ease-in-out;
  text-transform: uppercase;
}
.TitleContainer_span__2ArZ4 {
  font-family: 'Skr Games';
  font-style: normal;
  font-weight: normal;
  font-size: 100px;
}
.TitleContainer_container__2IdLD {
  position: relative;
  width: 100vw;
}
.TitleContainer_visibility__UnL3w {
  visibility: hidden;
}
@media screen and (min-width: 2560px) {
  .TitleContainer_h1__zYHeA {
    font-size: 200px;
  }
}

@media screen and (max-width: 400px) {
  .TitleContainer_h1__zYHeA {
    font-size: 130px;
    line-height: 30%;
  }
  .TitleContainer_span__2ArZ4 {
    font-size: 50px;
  }
}
.TitleContainer_load__3a4_L {
  opacity: 0;
  height: 289px;
}
.TitleContainer_loadSmallSize__3VySe {
  opacity: 0;
  height: calc(150px + 40vw);
}

.TitleContainer_loadh1__YSTlS {
  -webkit-animation: TitleContainer_blink__pybpM 2s infinite ease-in-out;
          animation: TitleContainer_blink__pybpM 2s infinite ease-in-out;
  margin-top: 194px;
}

@-webkit-keyframes TitleContainer_blink__pybpM {
  50% {
    opacity: 0.2;
  }
}

@keyframes TitleContainer_blink__pybpM {
  50% {
    opacity: 0.2;
  }
}

* {
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  -webkit-user-select: none;
          user-select: none;
}

.MainContainer_wrapper_container_load__2EO7N {
  height: 100vh;
  background: radial-gradient(ellipse at center, rgba(42, 21, 171, 1) 0%, rgba(2, 0, 36, 1) 72%);
  overflow: hidden;
}

.MainContainer_container3__33EIO {
  box-sizing: border-box;
  background: radial-gradient(ellipse at center, rgba(42, 21, 171, 1) 0%, rgba(2, 0, 36, 1) 72%);
  overflow: hidden;
}

.MainContainer_container2__2N7c6 {
  background: radial-gradient(circle, rgba(42, 21, 171, 1) 0%, rgba(2, 0, 36, 1) 72%);
}

.MainContainer_load__1oQbr {
  display: none;
}

