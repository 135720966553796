.bookmark {
  position: absolute;
  width: 311px;
  animation: crown-animation 1s ease-in-out;
  right: 0;
}

@media screen and (min-width: 2560px) {
  .bookmark {
    width: 411px;
  }
}

@media screen and (max-width: 1440px) {
  .bookmark {
    top: 100px;
    width: 200px;
  }
}
@media screen and (max-width: 900px) {
  .bookmark {
    width: 200px;
  }
}

@media screen and (max-width: 680px) {
  .bookmark {
    width: 30vw;
  }
}

@media screen and (max-width: 400px) {
  .bookmark {
    top: 60px;
  }
}
.load {
  display: none;
}

@keyframes crown-animation {
  0% {
    transform: translatex(600px);
  }

  100% {
    transform: translatex(0px);
  }
}
