.phone {
  background-image: url(../../img/longhand.png);
  background-repeat: no-repeat;
  position: relative;
  margin-top: 30px;
  margin-left: -169px;

  background-size: 1298px;
  transition: transform 1s ease-in-out;
  height: 900.25px;
}

.load {
  transform: translateX(-2000px);
}

.persp {
  width: 300px;
  height: 300px;
  margin-left: 150px;
  background: center center no-repeat;
  background-size: contain;
  perspective-origin: 134% 130%;

  perspective: 2000px;

  transform-style: preserve-3d;
  backface-visibility: hidden;
}

.video {
  height: 443px;
  width: 187px;
  border-radius: 20px;
  border-top-right-radius: 23.5px;
  margin-left: 796px;
  margin-top: 110px;

  transform: skew(-4.25deg, 0deg) rotateZ(-1deg) rotateY(-17deg) rotateX(13.5deg);
  transform-origin: 0 0;
  z-index: 3;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  object-fit: fill;
}

@media screen and (min-width: 2000px) {
  .phone {
    background-size: 1698px;
    height: 100vh;
    margin-left: -20vw;
  }
  .persp {
    perspective-origin: 1% 47%;
    perspective: 3500px;
  }
  .video {
    height: 565px;
    width: 234px;
    border-radius: 21px;
    border-top-right-radius: 23.5px;
    margin-left: 1090px;
    margin-top: 144px;
    -webkit-transform: skew(-4.25deg, 0deg) rotateZ(-1deg) rotateY(-17deg) rotateX(13.5deg);
    transform: skew(-4deg, -2.7deg) rotateZ(0deg) rotateY(-20deg) rotateX(15.5deg);
  }
}
@media screen and (min-width: 2200px) {
  .phone {
    margin-left: -15vw;
  }
}

@media screen and (min-width: 2300px) {
  .phone {
    margin-left: -10vw;
  }
}
@media screen and (max-width: 1700px) {
  .phone {
    margin-left: -15vw;
  }
}
@media screen and (max-width: 1440px) {
  .phone {
    margin-left: -25vw;
    height: 690px;
  }
}
@media screen and (max-width: 1300px) {
  .phone {
    background-size: 1039px;
    margin-left: -12vw;
  }
  .persp {
    perspective-origin: 176% 115%;
    -webkit-perspective: 2000px;
    perspective: 1500px;
  }
  .video {
    height: 354px;
    width: 154px;
    border-radius: 16px;
    border-top-right-radius: 16.5px;
    margin-left: 607px;
    margin-top: 88px;
    -webkit-transform: skew(-4.25deg, 0deg) rotateZ(-1deg) rotateY(-17deg) rotateX(13.5deg);
    transform: skew(-4.1deg, 0.5deg) rotateZ(-1deg) rotateY(-11deg) rotateX(11.5deg);
  }
}
@media screen and (max-width: 1170px) {
  .phone {
    margin-left: -20vw;
  }
}
@media screen and (max-width: 1100px) {
  .phone {
    margin-left: -25vw;
  }
}
@media screen and (max-width: 1024px) {
  .phone {
    margin-left: -35vw;
  }
}

@media screen and (max-width: 950px) {
  .phone {
    background-size: 550px;
    background-image: url(../../img/Phone.png);
    height: 450px;
    margin-top: 50px;
    margin-left: 0;
  }
  .persp {
    perspective-origin: -6% -2%;
    perspective: 1800px;
  }
  .video {
    height: 341px;
    width: 151px;
    border-radius: 16px;
    border-top-right-radius: 20.5px;
    margin-left: 185px;
    margin-top: 9px;

    transform: skew(-4.5deg, -0.9deg) rotateZ(-1.9deg) rotateY(-15deg) rotateX(15.5deg);
  }
}

@media screen and (max-width: 680px) {
  .phone {
    background-size: 465px;
    height: 450px;
    margin-top: 50px;
  }
  .persp {
    perspective-origin: -6% -2%;
    perspective: 1500px;
  }
  .video {
    height: 286px;
    width: 128px;
    border-radius: 13.5px;
    border-top-right-radius: 13.5px;
    margin-left: 132px;
    margin-top: 9px;
    transform: skew(-4.5deg, -0.9deg) rotateZ(-1.9deg) rotateY(-15.5deg) rotateX(12.5deg);
  }
}
@media screen and (max-width: 600px) {
  .phone {
    margin-top: -100px;
  }
}

@media screen and (max-width: 550px) {
  .phone {
    margin-left: -80px;
  }
}

@media screen and (max-width: 500px) {
  .phone {
    margin-left: -80px;
  }
}
@media screen and (max-width: 450px) {
  .phone {
    margin-left: -133px;
    height: 350px;
  }
}

@media screen and (max-width: 360px) {
  .phone {
    margin-left: -173px;
  }
}
