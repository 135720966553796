.meteor {
  background-image: url(../../img/Meteor.png);
  background-repeat: no-repeat;
  position: absolute;
  width: 281.2px;
  height: 324.54px;
  bottom: -530px;
  right: 230px;
  animation: do-levitation 1.2s alternate ease-in-out infinite;
}
.background {
  background: radial-gradient(circle, rgb(99, 0, 180) 0%, rgba(2, 0, 36, 1) 100%);
  filter: blur(180px);
  -webkit-transform: translateZ(0);
  position: absolute;
  height: 400px;
  width: 800px;
  z-index: 0;
  right: 0;
  top: 700px;
}

@keyframes do-levitation {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(0, 5vw);
  }
}

@media screen and (max-width: 1560px) {
  .meteor {
    top: 500px;
    height: 300px;
    width: 250px;
    right: 100px;
  }
  .background {
    top: 600px;
    height: 300px;
    filter: blur(100px);
    width: 400px;
  }
}
@media screen and (max-width: 1200px) {
  .meteor {
    top: 200px;
    right: 100px;
  }
  .background {
    filter: blur(90px);
    top: 200px;
  }
}

@media screen and (max-width: 700px) {
  .meteor {
    top: 200px;
    right: 2vw;
    background-size: 30vw;
    width: 30vw;
    height: 30vw;
  }
  .background {
    display: none;
  }
}

@media screen and (max-width: 500px) {
  .meteor {
    top: 100px;
    right: 2vw;
  }
}
