@font-face {
  font-family: 'Monument Extended';
  src: local('Monument Extended'), url('./fonts/MonumentExtended-Regular.otf');
}
@font-face {
  font-family: 'Skr Games';
  src: local('Skr Games'), url('./fonts/Pink\ Blue.ttf');
}

* {
  font-family: 'Monument Extended';
}
