* {
  color: #ffffff;
  text-transform: uppercase;
}
.name {
  width: 320px;
  background: none;
  border: none;
  font-size: 21px;
  border-bottom: 2px solid white;
  padding-bottom: 20px;
  margin-top: 70px;
  margin-bottom: 50px;
  outline: none;
  user-select: text;
}

.containerBtn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn {
  background: transparent;
  outline: none;
  border: none;
  border-radius: 50%;
  transform: translate(-50%, 0%);
  box-sizing: border-box;
  font-size: 27px;
  line-height: 150%;
  width: 240px;
  height: 240px;
  letter-spacing: 0.05em;
  margin-left: 200px;
  margin-top: -50px;
}

.circle {
  stroke-dasharray: 1000;
  stroke-dashoffset: 0;
  cursor: pointer;
  transition: all 1.8s ease-in-out;
}
.circleDec {
  fill-opacity: 0.4;
  stroke-dashoffset: 1000;
}
.anchor {
  display: block;
  position: relative;
  top: -50px;
  visibility: hidden;
}

.btn > svg {
  position: absolute;
  left: 0;
  top: 0;

  stroke: #fff;
  fill: #4f95da;
  stroke-linecap: round;
  stroke-linejoin: round;
  transition: 1s ease-in-out;
}

.btn span {
  color: white;
  font-size: 18px;
  font-weight: 100;
}

h6 {
  font-size: 90px;
  line-height: 80px;
}
.text {
  width: 92%;
}
textarea {
  width: 100%;
  height: 273px;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.05em;
  padding-right: 1vw;
  background: none;
  outline: none;
  border: none;
  resize: none;
  user-select: text;
}

::-webkit-scrollbar {
  width: 10px;
  cursor: pointer;
}

::-webkit-scrollbar-track {
  background: #010514;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.8);
  border-radius: 100px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(107, 107, 107);
}

textarea::-webkit-scrollbar-track {
  background: #010514;
  border-radius: 100px;
}

.container {
  margin-top: 400px;
  margin-left: 46px;
  margin-bottom: 120px;
}
.wrapper {
  border: 1px solid #ffffff;
  box-sizing: border-box;
  width: 60vw;
  height: 305px;
  padding-left: 15px;
  padding-top: 10px;
}

.flex {
  display: flex;
  align-items: center;
}
.subTitle {
  font-size: 8px;
  line-height: 10px;
  margin-top: 30px;
  display: flex;
  align-items: flex-end;
  letter-spacing: 0.1em;
}

@media screen and (min-width: 1440px) and (max-width: 2560px) {
  textarea {
    font-size: 23px;
  }
}

@media screen and (min-width: 2560px) {
  .name {
    font-size: 38px;
    width: 500px;
  }
  button {
    width: 300px;
    height: 300px;
    font-size: 40px;
  }
  .text {
    width: 95%;
  }
  textarea {
    font-size: 30px;
    width: 100%;
    padding-right: 0.1vw;
  }
  .wrapper {
    width: 1350px;
  }
  .subTitle {
    font-size: 10px;
  }
}

@media screen and (max-width: 1200px) {
  .flex {
    flex-direction: column;
  }
  .wrapp_container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .name {
    width: 80vw;
  }
  .wrapper {
    width: 80vw;
  }
  .flex {
    width: 80vw;
  }
  textarea {
    width: 75vw;
    font-size: 20px;
  }
  .btn {
    margin-top: 50px;
  }
  .container {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 900px) {
  h6 {
    margin-left: 9vw;
    font-size: 12vw;
    line-height: 100%;
  }
  .anchor {
    top: -18vw;
  }
  .container {
    margin-top: 150px;
  }
}
@media screen and (max-width: 700px) {
  .anchor {
    top: -22vw;
  }
}

@media screen and (max-width: 650px) {
  .container {
    margin-left: 0;
  }
  h6 {
    font-style: normal;
    font-weight: normal;
  }
}

@media screen and (max-width: 450px) {
  h6 {
    font-size: 30px;
  }
  .anchor {
    top: -20px;
  }
  .containerBtn {
    width: 240px;
  }
  .container {
    margin-top: 50px;
  }
  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 238px;
  }
}
