.h1 {
  font-family: 'Skr Games';
  font-style: normal;
  font-weight: normal;
  font-size: 180px;
  line-height: 39%;
  color: #fff;
  text-align: center;
  opacity: 1;
  transition: opacity 2s ease-in-out;
  text-transform: uppercase;
}
.span {
  font-family: 'Skr Games';
  font-style: normal;
  font-weight: normal;
  font-size: 100px;
}
.container {
  position: relative;
  width: 100vw;
}
.visibility {
  visibility: hidden;
}
@media screen and (min-width: 2560px) {
  .h1 {
    font-size: 200px;
  }
}

@media screen and (max-width: 400px) {
  .h1 {
    font-size: 130px;
    line-height: 30%;
  }
  .span {
    font-size: 50px;
  }
}
.load {
  opacity: 0;
  height: 289px;
}
.loadSmallSize {
  opacity: 0;
  height: calc(150px + 40vw);
}

.loadh1 {
  animation: blink 2s infinite ease-in-out;
  margin-top: 194px;
}

@keyframes blink {
  50% {
    opacity: 0.2;
  }
}
