h2 {
  font-size: 90px;
  line-height: 80px;
  margin-left: 46px;
  padding-top: 500px;
  margin-bottom: 116px;
  text-transform: uppercase;
  color: #ffffff;
}
.container {
  position: relative;
}

.anchor {
  display: block;
  position: relative;
  top: -150px;
  visibility: hidden;
}

@media screen and (max-width: 774px) {
  .anchor {
    top: -230px;
  }
}
@media screen and (max-width: 600px) {
  h2 {
    padding-top: 200px;
  }
  .anchor {
    top: -260px;
  }
}
@media screen and (max-width: 550px) {
  h2 {
    padding-top: 100px;
    font-size: 45px;
  }
  .anchor {
    top: -160px;
  }
}

@media screen and (max-width: 450px) {
  .container {
    overflow: hidden;
  }
  .anchor {
    top: -130px;
  }
  h2 {
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 89%;
    padding-top: 150px;
    margin-left: 7vw;
  }
}
