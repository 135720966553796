.header {
  display: flex;
  width: 95vw;
  margin: auto;
  padding-top: 30px;
  justify-content: space-between;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;

  line-height: 150%;
  margin-bottom: 130px;
  animation: menuTranslate 1s ease-in-out;

  /* identical to box height, or 21px */

  text-transform: uppercase;
  color: #ffffff;
}
.toogle {
  height: 3px;
  width: 30px;
  position: absolute;
  right: 15px;
  top: 30px;
  background-color: white;
}

.toogle::after {
  content: '';
  position: absolute;

  width: 19px;
  height: 2.7px;
  top: 8px;
  right: 0;
  background: white;
}
.flexMenu {
  display: flex;
  position: fixed;
  width: calc(16.4px + 100vw);
  z-index: 10;
  margin-top: -50px;
  margin-left: -16.4px;
  height: 103vh;
  flex-direction: column;
  background: radial-gradient(ellipse at center, rgba(42, 21, 171, 1) 0%, rgba(2, 0, 36, 1) 72%);
  padding-top: 100px;
}

.toogleDecoration {
  margin-left: 50px;
  text-decoration: none;
  font-size: 30px;
  margin-bottom: 60px;
  transform-origin: top center;
  animation: translate 0.7s alternate ease-in-out;
}
@keyframes menuTranslate {
  0% {
    transform: translatey(-600px);
  }

  100% {
    transform: translatey(0px);
  }
}

@keyframes translate {
  0% {
    opacity: 0;
    transform: translateX(100px);
  }

  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}
@keyframes closeToogleMenu {
  0% {
    opacity: 0;
    transform: translateY(-100px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
.toogle::before {
  content: '';
  position: absolute;
  width: 13px;
  height: 2.7px;
  top: -8px;
  right: 0;
  background: white;
}

.close {
  position: absolute;
  right: 4.125vw;
  top: 45px;
  width: 32px;
  height: 32px;
  animation: closeToogleMenu 0.7s alternate ease-in-out;
}
.close:hover {
  opacity: 0.3;
}
.close:before,
.close:after {
  position: absolute;
  left: 15px;
  content: ' ';
  height: 33px;

  width: 2px;
  background-color: white;
}
.close:before {
  transform: rotate(45deg);
}
.close:after {
  transform: rotate(-45deg);
}

@media screen and (min-width: 2560px) {
  .header {
    font-size: 20px;
  }
}

.decoration {
  position: relative;
  cursor: pointer;
  line-height: 1;
  text-decoration: none;
}
.decorationMobileGame {
  position: relative;
  cursor: pointer;
  line-height: 1;
  text-decoration: none;
}

.decoration:after {
  display: block;
  position: absolute;
  left: 0;
  width: 0;
  height: 2px;
  margin-top: 5px;
  background-color: white;
  content: '';
  transition: width 0.3s ease-out;
}

.decoration:hover:after,
.decoration:focus:after {
  width: 100%;
}

.nav {
  display: flex;
  justify-content: space-between;
}

.nav > * {
  margin-left: 6vw;
}

.load {
  display: none;
}

@media screen and (max-width: 700px) {
  .header {
    padding-top: 15px;
  }
}
