.container {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  margin-bottom: 100px;
}
h4 {
  font-size: 40px;
  line-height: 89%;
  margin-bottom: 45px;
  text-transform: uppercase;
}
.textGooglePlay {
  margin-top: 3px;
  cursor: pointer;
}
.arrowGooglePlay {
  height: 35px;
}
.wrapper_buttons {
  display: flex;
  align-items: center;
}
.wrapper_googleplay {
  display: flex;
  align-items: center;
  margin-left: 60px;
}

.wrapper {
  width: 616px;
  margin-left: 90px;
  z-index: 3;
}
.wrapper p {
  font-size: 15px;
  line-height: 150%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  margin-bottom: 70px;
}
.wrapper img {
  cursor: pointer;
}
.wrapRate {
  display: flex;
  align-items: center;
  font-size: 22px;
  height: 30px;
  line-height: 89%;

  margin-left: 30px;
  margin-bottom: 30px;
}

.wrapRate p {
  margin-top: 5px;
  margin-left: 10px;
}
.background {
  display: flex;
  z-index: 3;
  align-items: flex-end;
  width: 300px;
  height: 300px;
}

@media screen and (max-width: 1280px) {
  .container {
    flex-direction: column;
    margin-bottom: 80px;
  }

  .wrapper {
    margin-top: 40px;
    text-align: center;
    margin-left: 0;
  }
  .wrapper p {
    margin-bottom: 40px;
  }
  .wrapper_googleplay {
    margin-left: 0;
    margin-right: 70px;
  }
  .wrapper_buttons {
    align-items: center;
    justify-content: space-evenly;
  }
  .wrapper_buttons > div {
    margin-right: 60px;
    margin-left: 0;
  }
}

@media screen and (min-width: 1281px) and (max-width: 1440px) {
  .container {
    align-items: flex-start;
  }
  .wrapper {
    width: 450px;
  }
  .wrapper p {
    margin-bottom: 20px;
  }
  .wrapper h4 {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 700px) {
  .wrapper {
    display: flex;
    width: 95vw;
    flex-direction: column;
    margin-left: 0;
    justify-content: center;
    text-align: center;
  }
  .wrapper_googleplay {
    margin-left: 0;
    margin-right: 0;
  }
  .wrapper p {
    font-size: 15px;
  }
  .wrapper_buttons > div {
    margin-right: 0;
    margin-left: 0;
  }
}

@media screen and (max-width: 500px) {
  h4 {
    font-size: 25px;
    line-height: 100%;
  }
  .wrapper_googleplay {
    margin-left: 0;
    margin-right: 0;
  }
}
