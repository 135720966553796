.weapon {
  background-image: url(../../img/Weapon.png);
  background-repeat: no-repeat;
  position: absolute;
  width: 453px;
  height: 742px;
  left: 0;
  bottom: -120px;
}
@media screen and (max-width: 900px) {
  .weapon {
    width: 320px;
    left: -100px;
    bottom: -50px;
  }
}
@media screen and (max-width: 670px) {
  .weapon {
    bottom: -174px;
  }
}

@media screen and (max-width: 950px) {
  .parallax {
    display: none;
  }
  .weapon {
    display: none;
  }
}
