.bomb {
  background-repeat: no-repeat;
  width: 220px;
  height: 364px;
  animation: crown-animation 1s ease-in-out;
}
@media screen and (min-width: 2560px) {
  .bomb {
    background-size: 100%;
    top: 200px;
    width: 300px;
    height: 500px;
  }
}

@media screen and (max-width: 1600px) {
  .bomb {
    width: 200px;
    height: 300px;
  }
}

@media screen and (max-width: 1300px) {
  .bomb {
    width: 150px;
    height: 225px;
  }
}

@media screen and (max-width: 950px) {
  .bomb {
    top: 50px;
    width: 150px;
    height: 250px;
  }
}

@media screen and (max-width: 680px) {
  .bomb {
    top: 50px;
    width: 20vw;
    height: 32vw;
    margin-bottom: 50px;
    margin-top: 20px;
  }
}

.load {
  display: none;
}

@keyframes crown-animation {
  0% {
    transform: translatex(-600px);
  }

  100% {
    transform: translatex(0px);
  }
}
