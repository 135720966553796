* {
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  -moz-user-select: none;
  -khtml-user-select: none;
  user-select: none;
}

.wrapper_container_load {
  height: 100vh;
  background: radial-gradient(ellipse at center, rgba(42, 21, 171, 1) 0%, rgba(2, 0, 36, 1) 72%);
  overflow: hidden;
}

.container3 {
  box-sizing: border-box;
  background: radial-gradient(ellipse at center, rgba(42, 21, 171, 1) 0%, rgba(2, 0, 36, 1) 72%);
  overflow: hidden;
}

.container2 {
  background: radial-gradient(circle, rgba(42, 21, 171, 1) 0%, rgba(2, 0, 36, 1) 72%);
}

.load {
  display: none;
}
